import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../templates/layout'
import Column2 from '../templates/column2'
import Header from '../organisms/header'
import Footer from '../organisms/footer'
import MainContent from '../organisms/main-content'
import Sidebar from '../organisms/sidebar'
import PostList from '../organisms/post-list'
import Pagination from '../organisms/pagination'
import SEO from '../utils/seo'

export default ({ pageContext, data }) => {
  const { edges } = data.allMarkdownRemark
  const { currentPage, totalPages, canonical } = pageContext
  const isFirst = currentPage === 1
  const headerTitle = !isFirst && `${currentPage}ページ目`
  const title = !isFirst && `記事一覧 ${currentPage}ページ目`
  const titleTemplate = isFirst && '%s'

  return (
    <Layout>
      <SEO title={title} titleTemplate={titleTemplate} canonical={canonical} />
      <Header />
      <Column2>
        <MainContent>
          <PostList posts={edges} headerTitle={headerTitle} />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            pathPrefix={'/blog'}
            firstPagePath={'/'}
          />
        </MainContent>
        <Sidebar />
      </Column2>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            description
            tags {
              id
              slug
            }
          }
          fields {
            slug
          }
          id
          html
          excerpt
        }
      }
    }
  }
`
